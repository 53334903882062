import moment from "moment";
import React, { useRef, useState } from "react";
// Import Swiper React components
function Services() {
  
  const [section, setSection] = useState('Security')


  return (
    <div className="App">


<div class="contact-title" style={{
  paddingBottom : 100,
  paddingTop : 100
}}>
<div class="d-table">
<div class="d-table-cell">
<div class="title-text text-center">
<h2>Our Services</h2>
<ul>
<li><a href="/">Home</a></li>
<li>
Our Services
</li>
</ul>
</div>
</div>
</div>
</div>

<section class="portfolio-section " style={{paddingTop : 50}}>
<div class="container">
<div class="section-title text-center" style={{maxWidth : 1000}}>
{/* <h2>Our Clients</h2> */}
<p>At T9 Security Services, we pride ourselves on delivering a diverse range of top-notch services designed to meet the unique needs of our clients. From cutting-edge security solutions to personalized consultation services, we are committed to ensuring the safety and satisfaction of our clients. Our experienced team is dedicated to excellence, employing the latest technologies and industry best practices to deliver reliable and effective results. Discover peace of mind with our comprehensive suite of services tailored to safeguard what matters most to you.</p>
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}
</div>

</div>
</section>


<section class="features-section pb-70">
<div class="container">

<div class="row">

<div class="col-lg-4 col-sm-6">
<div class="features-card feature-three">
<i class="flaticon-settings"></i>
<h3>Security Services</h3>
<p>We introduce to you “T-9 Security Services Private Limited” We offer specialised services for Uniformed Security guard / Outsourcing services, which are manned by well-trained, experienced & skilled staff. Our’s is a Private Limited Company incorporated under the registrar of Companies Act, 1956.<br/>
<span className="read-more-serv" onClick={()=>{
  // window.location.href = '/services'
  setSection('Security')
}}> Read More…</span>
</p>
</div>
</div>

<div class="col-lg-4 col-sm-6">
<div class="features-card feature-two">
<i class="flaticon-megaphone"></i>
<h3>Outsourcing Services</h3>
<p>We provide complete HR solution with compliance’s of various Govt. Stationary liabilities of employees outsource through us. Wage distribution, Provident Fund, ESIC, Bonus, Gratuity all liabilities of off roll employees shall be taken care by us through our payroll management systems.<br/>
<span className="read-more-serv" onClick={()=>{
  // window.location.href = '/services'
  setSection('Outsource')
}}> Read More…</span>
</p>

</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="features-card feature-one">
<i class="flaticon-technical-support"></i>
<h3>Staffing Services</h3>
<p>We render staffing services of allied activities such as Stenos, Clerks, Typists, Computer Operators, Driver, loading / unloading & skilled labours, Executives, Sales and finance etc., which includes recruitments, HR Process, Wage distribution and Govt. compliance’s, thus providing you a hassle free HR Solution.<br/>
<span className="read-more-serv" onClick={()=>{
  // window.location.href = '/services'
  setSection('Staffing')
}}> Read More…</span>

</p>
</div>
</div>

</div>
</div>
</section>

<div style={{display : section == 'Security' ? '' : 'none'}}>
<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Security Services</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We introduce to you “T-9 Security Services Private Limited” We offer specialised services for Uniformed Security guard / Outsourcing services, which are manned by well-trained, experienced & skilled staff. Our’s is a Private Limited Company incorporated under the registrar of Companies Act, 1956.
<br/>
<br/>
Security Services is our core competence and we believe in providing cost effective security across multiple location in Rajasthan. Provisions of 24 hours security services for :-
</p>
<div className="row">
<div class="col-md-6">
<ul>
<li className="mx-width-serv">
<i class="flaticon-checked"></i>
Government offices and industries
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Manufacture / production and services industries etc.
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Hotel industries
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Hospital industries
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Banking industries
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Education industries
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Shopping malls
</li>
</ul>
</div>
<div class="col-md-6">
<ul>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Events management
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Residential houses and apartment etc.
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Any kind of commercial / business sector
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Provision of security escorts for marriages, private function etc. on a day basis
</li>
<li  className="mx-width-serv">
<i class="flaticon-checked"></i>
Investigation and verification of personnel for pre and post employment
</li>
<li className="mx-width-serv">
<i class="flaticon-checked"></i>
Outsourcing for all Government / Private sector
</li>
<li className="mx-width-serv">
<i class="flaticon-checked"></i>
Placement services.
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>


<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Custom Protection Officers</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
These officers are from the elite corps of the uniformed security officers and are selected by a screening criterion more stringent than that of any regulatory authority. These corps are selected to address highly critical facilities requiring the highest degree of reliability in the assigned security personnel.
</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>


<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Property Resource officers</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
Property resource officers are provided by our company for catering to the diverse and complex needs of multi-tenant corporate properties. These officers are a part of our security management programme which is designed to help the corporate’s free themselves from all their security responsibilities and focus on their core responsibilities.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

<section class="features-section pb-70">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Security Officers</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
Uniformed security officers on part –time or full time assignments are provided by our company ever since its inception. We are known in the industry for providing efficient security personnel to our clients.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

</div>

<div style={{display : section == 'Outsource' ? '' : 'none'}}>

<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Outsourcing Services & Staffing Services</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We provide complete HR solution with compliance’s of various Govt. Stationary liabilities of employees outsource through us. Wage distribution, Provident Fund, ESIC, Bonus, Gratuity all liabilities of off roll employees shall be taken care by us through our payroll management systems. Our sister concern company M/s Akshar Enterprises, provide you hassle free HR solution with all legal registrations taken as per labour laws requirements.
<br/><br/>
We render staffing services of allied activities such as Stenos, Clerks, Peons, Typists, Computer Operators, Drivers, loading, unloading & skilled labour, Executives, sales and finance etc., which includes recruitments, HR Process, Wage distribution and Govt. compliance’s thus providing you a hassle free Complete HR SOLUTION.
</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>


<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Recruitment Process</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We have a strong database of candidates for various posts/ departments. All candidates are primarily screened and their CV’s verified by our skilled and experienced HR executives.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Placement / Outsourcing</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We provide hassle free placements of candidates as per your need and requirements. The candidates are pre verified and judged for the suitable job / post for which he / she is sent.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>


<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Off-Roll Employees</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We also undertake employees on off rolls. Candidates working at your establishment can be given under our employment and they shall act as OFF ROLLS. We shall make monthly billing for all off roll employees and we shall be liable for their disbursement of wages. No master servant relationship between you and the employees.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Compliances</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
all compliance’s viz. PROVIDENT FUND, EMPLOYEES STATE INSURANCE, BONUS, GRATUITY, WORKMEN COMPENSATION MINIMUM WAGES etc., shall be our responsibility, thus providing you manpower with HASSEL FREE ENVIORNMENT.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

</div>

<div style={{display : section == 'Staffing' ? '' : 'none'}}>
<section class="features-section">
<div class="container">
<div class="section-title text-center" style={{maxWidth : '100%'}}>
<span>Staffing Services</span>
{/* <h2>Our Powerful Features</h2> */}
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}

<div class="service-section service-style-two" >
<div class="container-fluid">
<div class="row">
<div class="col-md-12 p-0">
<div class="service-text" style={{padding : 50, textAlign : '-webkit-center'}}>
<p style={{color : 'white'}}>
We render staffing services of allied activities such as Stenos, Clerks, Typists, Computer Operators, Driver, loading / unloading & skilled labours, Executives, Sales and finance etc., which includes recruitments, HR Process, Wage distribution and Govt. compliance’s, thus providing you a hassle free Complete HR SOLUTION.
</p>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>

</div>
    </div>
  );
}

export default Services;