import moment from "moment";
import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation  } from "swiper";

// Import Swiper React components
function Clients() {
  

  return (
    <div className="App">


<div class="contact-title" style={{
  paddingBottom : 100,
  paddingTop : 100
}}>
<div class="d-table">
<div class="d-table-cell">
<div class="title-text text-center">
<h2>Our Clients</h2>
<ul>
<li><a href="/">Home</a></li>
<li>
Our Clients
</li>
</ul>
</div>
</div>
</div>
</div>



<section class="portfolio-section pb-70" style={{paddingTop : 50}}>
<div class="container">
<div class="section-title text-center" style={{maxWidth : 1000}}>
{/* <h2>Our Clients</h2> */}
<p>We have a clientele of highly reputed organizations of Rajasthan comprising of Govt. Semi Govt. and Private Sector. We have laid best efforts to continue patronage of our esteemed client. With our consistency and service outputs</p>
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}
</div>

                    <div class="row gx-xxl-5">

                    <table >
   
      <tbody>
        <tr style={{textAlign: 'center', marginBottom : 50}}>
          <td>        <img src="/assets/img/Clients/A-G-FASHION.jpg" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" /></td>
          <td><img src="/assets/img/Clients/amuldairy.png" alt="" className="col-lg-4 zoom-stories-clients  img-sizing-stories" />
      </td>
          <td>        <img src="/assets/img/Clients/ATCS.jpg" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories"/>
</td>
          <td>        <img src="/assets/img/Clients/Case-Construction.jpg" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td style={{height : 30}}></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        

        <tr style={{textAlign: 'center'}}>
          <td>        <img src="/assets/img/Clients/dabur.png" alt="" className="col-lg-4 zoom-stories-clients  img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/GBH.png" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/sysbpo.jpeg" alt="" className="col-lg-4 zoom-stories-clients  img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/pinkcity.jpeg" alt="" className="col-lg-4 zoom-stories-clients  img-sizing-stories" />
</td>
        </tr>


        <tr style={{textAlign: 'center'}}>
          <td style={{height : 30}}></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td>        <img src="/assets/img/Clients/IPS.jpg" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/Samachar-Plus-UP-Uttarakhand.jpg" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/vrindavan.png" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
          <td>       <img src="/assets/img/Clients/Vaibhavglobal.png" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
        </tr>


        <tr style={{textAlign: 'center'}}>
          <td style={{height : 30}}></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td>        <img src="/assets/img/Clients/anand.png" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/dialme24.png" alt="" className="col-lg-6 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/raydean.png" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
          <td>       <img src="/assets/img/Clients/Webinfocare.jpg" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
        </tr>


        <tr style={{textAlign: 'center'}}>
          <td style={{height : 30}}></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td>        <img src="/assets/img/Clients/Extensive-Host-Private-Limited.png" alt="" className="col-lg-6 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/sanjeevini.jpg" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/Samachar-Plus-UP-Uttarakhand.jpg" alt="" className="col-lg-6 zoom-stories-clients img-sizing-stories" />
</td>
          <td>       <img src="/assets/img/Clients/rananjay.png" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td style={{height : 30}}></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr style={{textAlign: 'center'}}>
          <td>        
</td>
          <td>        <img src="/assets/img/Clients/gainful.png" alt="" className="col-lg-8 zoom-stories-clients img-sizing-stories" />
</td>
          <td>        <img src="/assets/img/Clients/raniwala.png" alt="" className="col-lg-4 zoom-stories-clients img-sizing-stories" />
</td>
          <td>      
</td>
        </tr>




      </tbody>
    </table>

    <img src="/assets/img/Clients/Head-Inner.gif" alt="" className="col-lg-5 zoom-stories-clients img-sizing-stories" style={{marginTop : 30}}/>

                       
        
     </div>

       
  
{/* <div class="theme-button text-center" style={{marginTop : -50}}>
<a href="/clients" class="default-btn">
Load More
</a>
</div> */}
</div>
</section>



    </div>
  );
}

export default Clients;