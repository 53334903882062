import React, {useState} from "react";
import { BrowserRouter as Router, NavLink, Route,Routes } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation  } from "swiper";

function Home() {

    return (
            <div className="App">

<div class="main-banner">
<div class="d-table">
<div class="d-table-cell">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-7 col-md-12">
<div class="banner-text">
<h1>Your Business Our Responsibility</h1>
<p>We introduce to you <br/> <b style={{fontSize : 20}}>“T-9 Security Services Private Limited”</b><br />
 We offer specialised services for Uniformed Security guard / Outsourcing services, which are manned by well-trained, experienced & skilled staff. Our’s is a Private Limited Company incorporated under the registrar of Companies Act, 1956.</p>
<div class="theme-button">
<a href="/contact-us" class="default-btn">Contact Us</a>

</div>
</div>
</div>
<div class="col-lg-5 col-md-12">
<div class="banner-image">
{/* <img src="/assets/img/finals/img1.png" alt="banner image" /> */}
<img src="/assets/img/guards/11.png" alt="banner image" />

</div>
</div>
</div>
</div>
</div>
</div>
<div id="particles-js"></div>
</div>



{/* Video Section */}


<div class="video-section pt-100">
<div class="container">
<div class="row">
<div class="col-lg-7">
<div class="video-text">
<a href="/contact-us" class="popup-vimeo">
<i class="fa fa-lightbulb-o"></i>
</a>
<p>Know More About Our Goals</p>
</div>
</div>
</div>
</div>
</div>



<div class="theory-section">
<div class="container">
<div class="row theory-area">
<div class="col-lg-9">
<div class="theory-text">
<h2>Our Theory is Customer Service is First</h2>
<p>Security Services is our core competence, and we believe in providing cost-effective security across multiple locations in Rajasthan. Provisions of 24-hour security services for residential complexes, commercial establishments, industrial facilities, educational institutions, and event venues are among our key offerings.</p>
<div class="signature-box">
<p>Anil Yaduvanshi</p>
<span>Founder and CEO</span>
</div>
</div>
</div>
<div class="theory-img" >
<img src="/assets/img/finals/img4.png" alt="theory image"  style={{}}/>
{/* <img src="/assets/img/guards/9.png" alt="banner image" /> */}
</div>
</div>
</div>
</div>


<section class="about-section pb-100">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-5">
<div class="about-image">
<img src="/assets/img/finals/img5.jpeg" alt="about image"  style={{height : 600}}/>
</div>
</div>
<div class="col-lg-6 offset-lg-1">
<div class="about-text">
<div class="section-title">
<span>Why Choose Us</span>
<h2>Choosing Security, Choosing Confidence</h2>
</div>
<div class="row">
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-podium icon-one"></i>
<p>100% Secure</p>
</div>
</div>
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-customer-service icon-two"></i>
<p>100% Support</p>
</div>
</div>
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-handshake icon-three"></i>
<p>100% Trusted</p>
</div>
</div>
</div>
<p>Our highly trained and professional security personnel are dedicated to ensuring the safety and well-being of our clients and their assets. <br/>
    We tailor our security solutions to meet the specific needs of each client, employing cutting-edge technology and strategic planning to enhance the overall security posture.  <br/><br/>
    By maintaining a vigilant presence and swift response capabilities, we aim to create a secure environment that allows our clients to focus on their core activities with peace of mind."</p>
<div class="theme-button">
<a href="/contact-us" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</section>


<section class="features-section pb-70">
<div class="container">
<div class="section-title text-center">
<span>Our Services</span>
<h2>Our Powerful Features</h2>
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}
</div>
<div class="row">

<div class="col-lg-4 col-sm-6">
<div class="features-card feature-three">
<i class="flaticon-settings"></i>
<h3>Security Services</h3>
<p>We introduce to you “T-9 Security Services Private Limited” We offer specialised services for Uniformed Security guard / Outsourcing services, which are manned by well-trained, experienced & skilled staff. Our’s is a Private Limited Company incorporated under the registrar of Companies Act, 1956.<br/>
<span className="read-more-serv" onClick={()=>{
  window.location.href = '/services'
}}> Read More…</span>
</p>
</div>
</div>

<div class="col-lg-4 col-sm-6">
<div class="features-card feature-two">
<i class="flaticon-megaphone"></i>
<h3>Outsourcing Services</h3>
<p>We provide complete HR solution with compliance’s of various Govt. Stationary liabilities of employees outsource through us. Wage distribution, Provident Fund, ESIC, Bonus, Gratuity all liabilities of off roll employees shall be taken care by us through our payroll management systems.<br/>
<span className="read-more-serv" onClick={()=>{
  window.location.href = '/services'
}}> Read More…</span>
</p>

</div>
</div>
<div class="col-lg-4 col-sm-6">
<div class="features-card feature-one">
<i class="flaticon-technical-support"></i>
<h3>Staffing Services</h3>
<p>We render staffing services of allied activities such as Stenos, Clerks, Typists, Computer Operators, Driver, loading / unloading & skilled labours, Executives, Sales and finance etc., which includes recruitments, HR Process, Wage distribution and Govt. compliance’s, thus providing you a hassle free HR Solution.<br/>
<span className="read-more-serv" onClick={()=>{
  window.location.href = '/services'
}}> Read More…</span>

</p>
</div>
</div>






</div>
</div>
</section>


<div class="service-section">
<div class="container-fluid">
<div class="row">
<div class="col-md-5 service-img">
</div>
<div class="col-md-7 offset-md-5 p-0">
<div class="service-text">
<h2> We provide Professional, Powerful, and Reliable Security Solutions!</h2>
<div class="row">
<div class="col-md-6">
<ul>
<li>
<i class="flaticon-checked"></i>
14 years of Experience
</li>
<li>
<i class="flaticon-checked"></i>
Corporate Services
</li>
<li>
<i class="flaticon-checked"></i>
T9s Provides Crowd Control Management. Reliable, Honest & Trusted Services.
</li>
</ul>
</div>
<div class="col-md-6">
<ul>
<li>
<i class="flaticon-checked"></i>
Providing Best Solutions
</li>
<li>
<i class="flaticon-checked"></i>
Protecting Lives & Property
</li>
<li>
<i class="flaticon-checked"></i>
Choosing Security, Choosing Confidence – Your Safety, Our Priority!
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>




<section class="portfolio-section pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Our Top Clients</h2>
<p>Safeguarding Success: Our Commitment to Your Security Excellence.</p>
{/* <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> */}
</div>

<Swiper   
                    // style={{width: 700}}
                    spaceBetween={10}
                    slidesPerView={5}
        // centeredSlides={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        <SwiperSlide
        style={{borderRadius : 50, paddingBottom : 50}}
        >
        <img src="/assets/img/Clients/HomePage/amuldairy.png" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25, marginTop : 50}}/>
        </SwiperSlide>

    
        <SwiperSlide >
        <img src="/assets/img/Clients/HomePage/dabur.png" alt="" className="col-lg-10 zoom-stories  img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide >
        <img src="/assets/img/Clients/HomePage/pinkcity.jpeg" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide >
        <img src="/assets/img/Clients/HomePage/raniwala.png" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/img/Clients/HomePage/raydean.png" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25, marginTop : 100}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/img/Clients/HomePage/sysbpo.jpeg" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

        <SwiperSlide>
        <img src="/assets/img/Clients/HomePage/Vaibhavglobal.png" alt="" className="col-lg-10 zoom-stories img-sizing-stories" style={{marginBottom : 25}}/>
        </SwiperSlide>

       
      </Swiper>

{/* <div class="theme-button text-center" style={{marginTop : -50}}>
<a href="/clients" class="default-btn">
Load More
</a>
</div> */}
</div>
</section>


<div class="progress-section ">
<div class="container-fluid">
<div class="row">
<div class="col-md-7 progress-image">
</div>
<div class="col-md-6 offset-md-6 p-0">
<div class="progress-text">
  
<h2>We Always Try to be the Best Support to Our Clients as Possible</h2>
<p>"At the heart of our service ethos lies an unwavering commitment to being the best support system for our clients. We strive tirelessly to exceed expectations, providing unparalleled assistance, understanding, and solutions. Your success is our motivation, and we stand ready to deliver the excellence you deserve."</p>

</div>
</div>
</div>
</div>
</div>


<section class="blog-section pt-100 pb-0">
<div class="container">
<div class="section-title text-center">
<h2>Our Achievements</h2>
<p style={{textAlign : 'center'}}>Celebrating Milestones: A Journey of Achievements and Appreciation</p>
</div>
<div class="row">
<div class="col-lg-4 col-md-6">
<div class="blog-card">
<div class="blog-img">
<a href="blog-details.html"><img src="/assets/img/certificate/Cert1.JPG" alt="blog image" /></a>
</div>
</div>
</div>

<div class="col-lg-4 col-md-6">
<div class="blog-card">
<div class="blog-img">
<a href="blog-details.html"><img src="/assets/img/certificate/Cert2.JPG" alt="blog image" /></a>
</div>
</div>
</div>


<div class="col-lg-4 col-md-6">
<div class="blog-card">
<div class="blog-img">
<a href="blog-details.html"><img src="/assets/img/certificate/Cert3.JPG" alt="blog image" /></a>
</div>
</div>
</div>


</div>
</div>
</section>



<div class="contact-section pb-100" style={{marginTop : 100}}>
<div class="container">
<div class="row">
<div class="col-lg-6 p-0 contact-img">
<img src="assets/img/contact.jpg" alt="contact" />
</div>
<div class="col-lg-6 p-0">
<div class="contact-form">
<div class="contact-text">
<h3>We Love to Hear From You</h3>
<p>Feel free and share with us. We will get you</p>
</div>
<form id="contactForm">
<div class="row">
<div class="col-md-12 col-sm-6">
<div class="form-group">
<input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-md-12 col-sm-6">
<div class="form-group">
<input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-md-12 col-sm-6">
<div class="form-group">
<input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="form-group">
<textarea name="message" class="message-field" id="message" cols="30" rows="5" required data-error="Please type your message" placeholder="Message"></textarea>
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12 col-md-12">
<button type="submit" class="default-btn contact-btn">
Send Message
</button>
<div id="msgSubmit" class="h3 text-center hidden"></div>
<div class="clearfix"></div>
</div>
</div>
</form>
<img src="assets/img/shapes/1.png" class="contact-shape" alt="shape" />
</div>
</div>
</div>
</div>
</div>


                </div>
        );
    }
    
    export default Home;