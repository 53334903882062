import moment from "moment";
import React, { useRef, useState } from "react";
// Import Swiper React components
function AboutUs() {
  

  return (
    <div className="App">
<div class="contact-title" style={{
  paddingBottom : 100,
  paddingTop : 100
}}>
<div class="d-table">
<div class="d-table-cell">
<div class="title-text text-center">
<h2>Who We Are</h2>
<ul>
<li><a href="/">Home</a></li>
<li>
Who We Are
</li>
</ul>
</div>
</div>
</div>
</div>


<section class="about-section pb-100">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-5">
<div class="about-image">
{/* <img src="/assets/img/finals/img8.png" alt="about image" style={{height : 500, width : 600}} /> */}
<img src="/assets/img/guards/14.jpg" alt="about image"  />

</div>
</div>
<div class="col-lg-6 offset-lg-1">
<div class="about-text">
<div class="section-title">
<span>About Us</span>
<h2>Your Trusted Partner in Comprehensive Security Services</h2>
</div>
<div class="row">
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-podium icon-one"></i>
<p>100% Secure</p>
</div>
</div>
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-customer-service icon-two"></i>
<p>100% Support</p>
</div>
</div>
<div class="col-md-3 col-sm-4">
<div class="about-us">
<i class="flaticon-handshake icon-three"></i>
<p>100% Trusted</p>
</div>
</div>
</div>
<p>This is to introduce to you the T9 Security Services Private Limited. We offer you highly specialized Security and outsourcing service, which are manned by well-trained, experienced and skilled staff.</p>

<div class="theme-button">
<a href="/contact-us" class="default-btn">Choose Us</a>

</div>
</div>
</div>
</div>
</div>
</section>


<div class="theory-section theory-style-two" style={{marginBottom : -300}}>
<div class="container">
<div class="row theory-area">
<div class="col-lg-12">
<div class="theory-text">
<h2>Our Theory</h2>
<p>
<b>OURSELVES:</b> Our’s is an incorporated Private Limited Company, registered under the companies act 1956, with the Registrar of Companies, Jaipur. We have work force of men posted at various sites in Jaipur Also we are registered under the Shops & Establishment Act, under the Labour Act licensed to supply manpower/ laborers, Under The Employees Provident Fund Act And The Employees State Insurance Act.
<br/><br/>
<b>EMPLOYMENT:</b> The employees posted at your premises shall remain under our direct employment and shall be paid their wages etc. by us. Thus you shall have no liability towards their employment. All statutory liabilities regarding compliance of Provident Fund and ESIC shall be solely our’s responsibility, which shall be deposited on monthly basis vide our permanent account no RJRAJ/0031124/000 for Provident Fund and 15000472220001018 for ESIC respectively. You cannot employ any of our employees for any reason whatsoever, failing which, we shall be liable to charge two month’s equivalent amount of that individual in lieu thereof.
<br/><br/>
<b>DAY/NIGHT CHECKING SERVICES:</b>  Even if you have your own security arrangement, we undertake to carry out surprise checks during day and night and keep you informed about the observations made by us.

<br/><br/>
<b>VERIFICATIONS:</b>  We carry our verifications of pre-and post employment purposes. This service shall help you out to get a trained, hard working and devoted employee for your organisation.
<br/>
We undertake FILE INVESTIGATIONS (F I’s) for various Financial Institutions who lend money, vehicles, house Loans, computer Loans or other categories of loans.
<br/>
We had been on Panel of various Banks, Govt & semi Govt Bodies and have made our full support in verifying the details about bad debts/recovery cases and about absconding persons.

<br/><br/>
<b>OUTSOURCING:</b> We provide hassle free complete solution for providing contractual employees/temporary staff. The employees selected by your organization shall be routed through us and are called our employees and you get a complete solution barring with monthly PF/ESIC compliances, returns and all related labour liabilities.
<br/>
For further information please log on to our website mentioned below.
<br/>
It will be our pleasure to be of any help to you.
</p>
{/* <div class="signature-box">
<img src="assets/img/signature.png" alt="signature" />
<p>Babatunde Smithi</p>
<span>Founder and CEO</span>
</div> */}
</div>
</div>
{/* <div class="theory-img">
<img src="assets/img/theory-img.png" alt="theory image" />
</div> */}
</div>
</div>
</div>


<div class="video-style-two video-style-three" style={{paddingBottom : 50, marginBottom : 100}}>
<div class="container-fluid">
<div class="d-table">
<div class="d-table-cell">
<div class="video-text text-center">
<a href="tel:919414046756" class="popup-vimeo">
<i class="flaticon-phone"></i>
</a>
<p style={{textAlign : 'center'}}>Contact Us Now</p>
</div>
</div>
</div>
</div>
</div>

<section class="team-section pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<span>Our Management</span>
<h2>Meet Our Board Members</h2>
</div>
<div class="row">


<div class="col-lg-3 col-sm-6">
<div class="team-card">
<div class="team-img">
<img src="assets/img/team/3.jpg" alt="team member" />
<div class="team-text">
<div class="team-social">
{/* <ul>
<li>
<a href="#"><i class="flaticon-facebook"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-twitter"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-instagram"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-linkedin"></i></a>
</li>
</ul> */}
</div>
<h3>Anil Yaduvanshi</h3>
<p style={{textAlign : 'center'}}>Chairman</p>
</div>
</div>
</div>
</div>

<div class="contact-section col-lg-9">
<div class="container">
<div class="row">
<div class="col-lg-12 p-0">
<div class="contact-form">
<p>
<b>Anil Yaduvanshi</b> is the Chairman of our Company. He has been on the Board of our Company since inception. He holds a post graduate degree (Master's in Commerce) in ABST from Rajasthan University, Jaipur.
<br/>
He has approximately 14 years of experience in the Security & Outsourcing Services business He began his career at NEXUS SECURITY SERVICES PRIVATE LIMITED in 2000 and has been engaged in the running of our organization, having quality of strategic management and business leadership. He is responsible for our long term vision and monitoring our Company's performance and devising the overall business plans. Under his Dynamic leadership with clear future vision, the company has progressed to become one of largest.
</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class='row'>
<div class="col-lg-3 col-sm-6">
<div class="team-card">
<div class="team-img">
<img src="assets/img/team/4.jpg" alt="team member" />
<div class="team-text">
<div class="team-social">
{/* <ul>
<li>
<a href="#"><i class="flaticon-facebook"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-twitter"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-instagram"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-linkedin"></i></a>
</li>
</ul> */}
</div>
<h3>Leena Yaduvanshi</h3>
<p style={{textAlign : 'center'}}>Director</p>
</div>
</div>
</div>
</div>

<div class="contact-section col-lg-9">
<div class="container">
<div class="row">
<div class="col-lg-12 p-0">
<div class="contact-form">
<p>
<b>Leena Yaduvanshi</b>  is the Director of our Company. Shw has been on the Board of our Company since inception. She holds a post graduate degree (Master's in Arts) in Political Science from Rajasthan University, Jaipur.<br/> She holds a degree of Human Rights in subject from Delhi University & diploma of commercial Art in mahila polo technical from Rajasthan university jaipur.
<br/>
She has been engaged in the running of our organization, having quality of strategic management and business leadership. She is responsible for our long term vision and monitoring our Company's performance and devising the overall business plans. Under her Dynamic leadership with clear future vision, the company has progressed to become one of largest.</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>


<div class="service-section service-style-two pb-100"  >
<div class="container-fluid">
<div class="row">
<div class="col-md-6 service-img" style={{height : '88%',backgroundImage : 'url("/assets/img/finals/img10.jpeg")'}} >
</div>
<div class="col-md-6 offset-md-6 p-0">
<div class="service-text">
<h2>We Understand the User Need and Those Servicesfor Them</h2>
<ul>
<li>
<i class="flaticon-checked"></i>
Manpower Resourcing
</li>
<li>
<i class="flaticon-checked"></i>
Hiring
</li>
<li>
<i class="flaticon-checked"></i>
Outsourcing
</li>
<li>
<i class="flaticon-checked"></i>
Verifications
</li>
</ul>
</div>
</div>
</div>
</div>
</div>

    </div>
  );
}

export default AboutUs;