import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./Header";

class Routing extends Component {

   render(){
       return(
        <BrowserRouter>
        {/* <div>
        <div class="whats-float">
    <a href="https://wa.me/+918447009334" target={'_blank'}>
        <i  class="fa fa-whatsapp"></i>
     
    </a>
</div>
<div class="insta-float">
    <a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}>
        <i class="fa fa-instagram"></i>
       
    </a>
</div>

<div class="call-float">
    <a href="https://instagram.com/healthfitsquare?utm_medium=copy_link" target={'_blank'}>
        <i class="fa fa-phone"></i>
  
    </a>
</div>
          </div> */}
        <div>
      
           <Header />
    </div>
 
    
    </BrowserRouter>
    
       )
   }
}

export default Routing;