import moment from "moment";
import React, { useRef, useState } from "react";
// Import Swiper React components
function Contact() {
  

  return (
    <div className="App">


<div class="contact-title">
<div class="d-table">
<div class="d-table-cell">
<div class="title-text text-center">
<h2>Contact Us</h2>
<ul>
<li><a href="/">Home</a></li>
<li>
Contact Us
</li>
</ul>
</div>
</div>
</div>
</div>


<div class="contact-area">
<div class="container">
<div class="row contact-box">
<div class="col-lg-4 col-md-6">
<div class="contact-card">
<i class="flaticon-pin"></i>
<h3>Location</h3>
<ul>
<li>Office No. 6, Plot no-8, Arjunpuri, Imli Phatak, Jaipur, Rajasthan 302015</li>
{/* <li>35/C Street, USA</li> */}
</ul>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="contact-card">
<i class="flaticon-call"></i>
<h3>Phone</h3>
<ul>
<li><a href={'tel:919414046756'}>+(91) 94140 46756</a></li>
<li><a href={'tel:01412597937'}>+(0141) 2597 937 </a></li>
</ul>
</div>
</div>
<div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
<div class="contact-card">
<i class="flaticon-envelope"></i>
<h3>Email</h3>
<ul>
<li><a href="mailto:info@t9security.com" class="__cf_email__" >info@t9security.com</a></li>
<li style={{color : '#fff'}}>this is text</li>
{/* <li><a href="mailto:infso@t9security" class="__cf_email__" >[email&#160;protected]</a></li> */}
</ul>
</div>
</div>
</div>
</div>
</div>


<div class="contact-section pt-100 pb-100">
<div class="container">
<div class="row justify-content-center">
<div class="col-lg-6 contact-img p-0">
<img src="assets/img/contact.jpg" alt="contact" />
</div>
<div class="col-lg-6 p-0">
<div class="contact-form">
<div class="contact-text">
<h3>We Love to Hear From You</h3>
<p>Feel free and share with us. We will get you</p>
</div>
<form id="contactForm">
<div class="row">
<div class="col-md-12">
<div class="form-group">
<input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-md-12">
<div class="form-group">
<input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-md-12">
<div class="form-group">
<input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12">
<div class="form-group">
<textarea name="message" class="message-field" id="message" cols="30" rows="5" required data-error="Please type your message" placeholder="Message" />
<div class="help-block with-errors"></div>
</div>
</div>
<div class="col-lg-12">
<button type="submit" class="default-btn contact-btn">
Send Message
</button>
<div id="msgSubmit" class="h3 text-center hidden"></div>
<div class="clearfix"></div>
</div>
</div>
</form>
<img src="assets/img/shapes/1.png" class="contact-shape" alt="shape" />
</div>
</div>
</div>
</div>
</div>


<div class="map">
<div class="container-fluid p-0">
{/* <div style="overflow:hidden;max-width:100%;width:500px;height:500px;"><div id="gmap-canvas" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Office+No.+6,+Plot+no-8,+Arjunpuri,+Imli+Phatak,+Jaipur,+Rajasthan+302015&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="googlecoder" href="https://www.bootstrapskins.com/themes" id="enable-map-data">premium bootstrap themes</a></div> */}
<iframe src="https://www.google.com/maps/embed/v1/place?q=Office+No.+6,+Plot+no-8,+Arjunpuri,+Imli+Phatak,+Jaipur,+Rajasthan+302015&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" aria-hidden="false" tabindex="0"></iframe>
</div>
</div>
    </div>
  );
}

export default Contact;