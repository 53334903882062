import moment from "moment";
import React, { useRef, useState } from "react";
// Import Swiper React components
function Footer() {
  

  return (
    <div className="App">

<div class="newsletter-section">
<div class="container">
<div class="newsletter-area">
<h2>Contact Us Now</h2>
<div class="col-lg-6 offset-lg-3 p-0">
<form class="newsletter-form" data-bs-toggle="validator">
<input type="email" value={"info@t9security.com"} class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off" />
<button class="default-btn electronics-btn" type="submit" onClick={()=>{
  window.open('mailto:info@t9security.com')
}}>
Send Email
</button>
<div id="validator-newsletter" class="form-result"></div>
</form>
</div>
<img src="assets/img/shapes/2.png" class="subscribe-shape" alt="shape" />
</div>
</div>
</div>

<footer class="footer-area">
<div class="container">
<div class="row">
<div class="col-md-3 col-sm-6">
<div class="footer-widget">
<div class="footer-logo">
<a href="index.html">
{/* <img src="assets/img/logo.png" alt="logo" />  */}
<h1 style={{color : 'white', fontSize : 20, fontWeight : 700}}>T9 SECURITY SERVICES PRIVATE LIMITED</h1>
</a>
</div>
<p>We offer specialised services for Uniformed Security guard / Outsourcing services, which are manned by well-trained, experienced & skilled staff.</p>

</div>
</div>
<div class="col-md-3 col-sm-6">
<div class="footer-widget pl-75">
<h3>Services</h3>
<ul>
<li>
<a href="/services">
<i class="flaticon-right-chevron"></i>
Security Services
</a>
</li>
<li>
<a href="/services">
<i class="flaticon-right-chevron"></i>
Outsourcing Services
</a>
</li>
<li>
<a href="/services">
<i class="flaticon-right-chevron"></i>
Staffing Services
</a>
</li>

</ul>
</div>
</div>
<div class="col-md-3 col-sm-6">
<div class="footer-widget pl-75">
<h3>Quick Links</h3>
<ul>
<li>
<a href="/">
<i class="flaticon-right-chevron"></i>
Home
</a>
</li>
<li>
<a href="/who-we-are">
<i class="flaticon-right-chevron"></i>
About Us
</a>
</li>
<li>
<a href="/services">
<i class="flaticon-right-chevron"></i>
Services
</a>
</li>
<li>
<a href="/our-clients">
<i class="flaticon-right-chevron"></i>
Our Clients
</a>
</li>
<li>
<a href="/contact-us">
<i class="flaticon-right-chevron"></i>
Contact Us
</a>
</li>
</ul>
</div>
</div>
<div class="col-md-3 col-sm-6">
<div class="footer-widget pl-75">
<h3>Find Us</h3>
<ul>
<li>
<i class="flaticon-pin" style={{marginRight : 10}}></i>
Office No. 6, Plot no-8,  <br/> <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arjunpuri, Imli Phatak, <br/><br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jaipur, Rajasthan 302015
</li>
<li>
<a href="tel:+919414046756">
<i class="flaticon-phone" style={{marginRight : 10}}></i>
+(91) 94140 46756
</a>
</li>

<li>
<a href="mailto:info@t9security.com">
<i class="flaticon-mail" style={{marginRight : 10}}></i>
<span class="__cf_email__" >info@t9security.com</span>
</a>
</li>

</ul>
</div>
</div>
</div>
</div>
<div class="copyright-area">
<div class="container">
<div class="row align-items-center">
<div class="col-md-6 lh-1 text-left">
<p>&copy;{moment().format('YYYY')} T9 Security Service Pvt. Ltd. All Rights Reserved.</p>
</div>
<div class="col-md-6 lh-1 text-end">
<ul>
<li><a href="privacy.html">Privacy Policy</a></li>
<li><a href="#">Terms & Conditions</a></li>
</ul>
</div>
</div>
</div>
</div>
</footer>

   {/* <!--Back To Start--> */}
        <a href="#" class="back-to-top">
            <i class="icofont-simple-up"></i>
        </a>
        {/* <!--Back To End--> */}
    </div>
  );
}

export default Footer;