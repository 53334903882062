import React, {useState} from "react";
import { BrowserRouter as Router, NavLink, Route,Routes } from "react-router-dom";
import AboutUs from "./AboutUs";
import Clients from "./Clients";
import Contact from "./Contact";
import Footer from "./Footer";
import Home from "./Home";
import Services from "./Services";

function Header() {


  return (
    <div className="App">

<header class="header-area">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-7 col-md-9">
<div class="header-left-text">
<ul>
<li>
<a href="tel:+919414046756">
<i class="flaticon-call"></i>
+(91) 94140 46756
</a>
</li>
<li>
<a href="mailto:info@t9security.com">
<i class="flaticon-envelope"></i>
<span class="__cf_email__">info@t9security.com</span>
</a>
</li>
{/* <li>
<i class="flaticon-pin"></i>
28/A Street, USA
</li> */}
</ul>
</div>
</div>
<div class="col-lg-5 col-md-3">
<div class="header-right text-end">
<div class="header-social">
<ul>
<li>
<a href="#"><i class="flaticon-facebook"></i></a>
</li>
<li>
<a href="#"><i class="flaticon-twitter"></i></a>
</li>
<li>
<a href="#"><i class="fa fa-rss"></i></a>
</li>
{/* <li>
<a href="#"><i class="flaticon-instagram"></i></a>
</li> */}
</ul>
</div>
</div>
</div>
</div>
</div>
</header>


<div className="navbar-area">

<div class="mobile-nav mean-container">
    <div class="mean-bar">
        <a class="meanmenu-reveal" style={{right: 0, left: 'auto', textAlign: 'center', textIndent: 0, fontSize: 18}}><span><span><span></span></span></span></a>
    <nav class="mean-nav">

    <ul class="navbar-nav m-auto" style={{display : 'none'}}>
<li class="nav-item">
<a href="/" className={window.location.pathname == '/' ?  "nav-link active" : "nav-link" }>Home</a>
</li>

<li class="nav-item">
<a href="/who-we-are" className={window.location.pathname == '/who-we-are' ?  "nav-link active" : "nav-link" }>Who we are</a>
</li>
<li class="nav-item">
<a href="/services" className={window.location.pathname == '/services' ?  "nav-link active" : "nav-link" }>Services</a>
</li>

<li class="nav-item">
<a href="/our-clients" className={window.location.pathname == '/our-clients' ?  "nav-link active" : "nav-link" } > Our Clients</a>
</li>



<li class="nav-item">
<a href="/contact-us" className={window.location.pathname == '/contact-us' ?  "nav-link active" : "nav-link" } >Contact Us</a>
</li>
</ul>


<div class="other-option">
<div class="search-bar">
<i class="flaticon-phone search-icon"></i>
</div>
</div>
<div class="other-option" style={{marginLeft : 10}}>
<div class="search-bar">
<i class="flaticon-mail search-icon"></i>
</div>
</div>

</nav></div>
<a href="index.html" class="logo">
<img src="assets/img/logo.png" alt="logo" />
</a>
</div>

<div class="mobile-nav">
<a href="/" class="logo">
<img src="/assets/img/logo.png" alt="logo" />
</a>
</div>

<div class="main-nav">
<div class="container">
<nav class="navbar navbar-expand-md navbar-light">
<a class="navbar-brand" href="/">
<img src="/assets/img/logo.png" className="main-logo" alt="logo" />
<img src="/assets/img/logo-two.png" class="white-logo" alt="logo" />
</a>
<div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
<ul class="navbar-nav m-auto">
<li class="nav-item">
<a href="/" className={window.location.pathname == '/' ?  "nav-link active" : "nav-link" }>Home</a>
</li>

<li class="nav-item">
<a href="/who-we-are" className={window.location.pathname == '/who-we-are' ?  "nav-link active" : "nav-link" }>Who we are</a>
</li>
<li class="nav-item">
<a href="/services" className={window.location.pathname == '/services' ?  "nav-link active" : "nav-link" }>Services</a>
</li>

<li class="nav-item">
<a href="/our-clients" className={window.location.pathname == '/our-clients' ?  "nav-link active" : "nav-link" } > Our Clients</a>
</li>



<li class="nav-item">
<a href="/contact-us" className={window.location.pathname == '/contact-us' ?  "nav-link active" : "nav-link" } >Contact Us</a>
</li>
</ul>

<div class="other-option">
<div class="search-bar">
<i class="flaticon-phone search-icon"></i>
</div>
</div>
<div class="other-option" style={{marginLeft : 10}}>
<div class="search-bar">
<i class="flaticon-mail search-icon"></i>
</div>
</div>

</div>
</nav>
</div>
</div>


</div>


    <Routes>

    <Route exact path={"/"} element={<Home />} />
    <Route exact path={"/contact-us"} element={<Contact />} />
    <Route exact path={"/our-clients"} element={<Clients />} />
    <Route exact path={"/who-we-are"} element={<AboutUs />} />
    <Route exact path={"/services"} element={<Services />} />





    </Routes>
<Footer />
    </div>

    );
}

export default Header;